import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/travis/build/bdTechies/prozuktischool.com/src/views/layouts/MainLayout.js";
import { Helmet } from 'react-helmet';
import { Box, Divider, SEO, Text } from '../views/components';
import AuthorList from '../views/pages/Authors/AuthorList';
import authors from '../../content/author.yaml';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    {
      /* import YAML from 'yamljs';
      const authors = YAML.parse(authorData); */
    }
    <SEO pageTitle="পরিচিতি" pagePath="/about/" mdxType="SEO" />
    <Box maxWidth={960} margin="0 auto" padding={{
      xs: 3,
      sm: 4
    }} mdxType="Box">
      <Text variant="h2" textAlign="center" mdxType="Text">
  প্রযুক্তি স্কুল এর সংক্ষিপ্ত পরিচিতি
      </Text>
      <Divider mdxType="Divider" />
      <h3 {...{
        "id": "আপনাদের-মেজবান",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#%E0%A6%86%E0%A6%AA%E0%A6%A8%E0%A6%BE%E0%A6%A6%E0%A7%87%E0%A6%B0-%E0%A6%AE%E0%A7%87%E0%A6%9C%E0%A6%AC%E0%A6%BE%E0%A6%A8",
          "aria-label": "আপনাদের মেজবান permalink",
          "className": "header-anchor-icon before"
        }}><svg parentName="a" {...{
            "version": "1.1",
            "x": "0px",
            "y": "0px",
            "viewBox": "0 0 162.656 162.656",
            "xmlSpace": "preserve",
            "width": "0.6em",
            "height": "0.6em",
            "fill": "#7950F2",
            "stroke": "#7950F2",
            "strokeWidth": "0"
          }}><path parentName="svg" {...{
              "d": "M151.764,10.894c-14.522-14.522-38.152-14.525-52.676-0.008l0.003,0.003L76.112,33.872l10.607,10.605l22.983-22.988 l-0.002-0.002c8.678-8.663,22.785-8.658,31.457,0.014c8.673,8.672,8.672,22.786,0,31.461l-34.486,34.484 c-4.201,4.202-9.787,6.516-15.729,6.516c-5.942,0-11.529-2.314-15.73-6.516L64.605,98.052c7.035,7.035,16.389,10.91,26.338,10.91 c9.949,0,19.303-3.875,26.335-10.91l34.487-34.484C166.284,49.043,166.284,25.413,151.764,10.894z"
            }}></path>{` `}<path parentName="svg" {...{
              "d": "M52.96,141.162L52.96,141.162c-8.675,8.67-22.788,8.668-31.461-0.005c-8.673-8.675-8.673-22.791-0.001-31.465L55.98,75.21 c8.675-8.674,22.789-8.674,31.462,0L98.05,64.604c-14.524-14.523-38.154-14.524-52.676,0L10.89,99.086 c-14.519,14.523-14.519,38.154,0.001,52.678c7.263,7.262,16.801,10.893,26.341,10.892c9.536,0,19.074-3.629,26.333-10.887 l0.002-0.001l22.984-22.99l-10.608-10.606L52.96,141.162z"
            }}></path>{` `}</svg></a>{`আপনাদের মেজবান`}</h3>
      <p>{`আসালামু আলাইকুম। আমি আপনাদের মেজবান শহীদুল ইসলাম মজুমদার। প্রযুক্তি স্কুলের বিভিন্ন বিষয়ের সমন্বয় এবং আপনাদের সামান্য খাতির যত্নের কাজ আমিই করে থাকি। পেশায় আমি একজন সফটওয়্যার প্রকৌশলী এবং পড়াশুনা করেছি কম্পিউটার বিজ্ঞান ও প্রকৌশল বিষয়ে ব্র্যাক বিশ্ববিদ্যালয় থেকে। কাজ এবং আলসেমির মাঝেই অল্প-স্বল্প সময় দেয়ার চেষ্টা করি এখানে। কথায় বলে, বেকার মানুষের ব্যস্ততা বেশি। অফিসের পর তো বেকারই থাকি। তাই ব্যস্ততাও অনেক বেশি। যে কারণে প্রযুক্তি স্কুল এতদিনে যতদূর যাওয়ার কথা তার ধারে কাছেও যায়নি।`}</p>
      <h3 {...{
        "id": "আমাদের-অন্যান্য-মেজবানগণ",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#%E0%A6%86%E0%A6%AE%E0%A6%BE%E0%A6%A6%E0%A7%87%E0%A6%B0-%E0%A6%85%E0%A6%A8%E0%A7%8D%E0%A6%AF%E0%A6%BE%E0%A6%A8%E0%A7%8D%E0%A6%AF-%E0%A6%AE%E0%A7%87%E0%A6%9C%E0%A6%AC%E0%A6%BE%E0%A6%A8%E0%A6%97%E0%A6%A3",
          "aria-label": "আমাদের অন্যান্য মেজবানগণ permalink",
          "className": "header-anchor-icon before"
        }}><svg parentName="a" {...{
            "version": "1.1",
            "x": "0px",
            "y": "0px",
            "viewBox": "0 0 162.656 162.656",
            "xmlSpace": "preserve",
            "width": "0.6em",
            "height": "0.6em",
            "fill": "#7950F2",
            "stroke": "#7950F2",
            "strokeWidth": "0"
          }}><path parentName="svg" {...{
              "d": "M151.764,10.894c-14.522-14.522-38.152-14.525-52.676-0.008l0.003,0.003L76.112,33.872l10.607,10.605l22.983-22.988 l-0.002-0.002c8.678-8.663,22.785-8.658,31.457,0.014c8.673,8.672,8.672,22.786,0,31.461l-34.486,34.484 c-4.201,4.202-9.787,6.516-15.729,6.516c-5.942,0-11.529-2.314-15.73-6.516L64.605,98.052c7.035,7.035,16.389,10.91,26.338,10.91 c9.949,0,19.303-3.875,26.335-10.91l34.487-34.484C166.284,49.043,166.284,25.413,151.764,10.894z"
            }}></path>{` `}<path parentName="svg" {...{
              "d": "M52.96,141.162L52.96,141.162c-8.675,8.67-22.788,8.668-31.461-0.005c-8.673-8.675-8.673-22.791-0.001-31.465L55.98,75.21 c8.675-8.674,22.789-8.674,31.462,0L98.05,64.604c-14.524-14.523-38.154-14.524-52.676,0L10.89,99.086 c-14.519,14.523-14.519,38.154,0.001,52.678c7.263,7.262,16.801,10.893,26.341,10.892c9.536,0,19.074-3.629,26.333-10.887 l0.002-0.001l22.984-22.99l-10.608-10.606L52.96,141.162z"
            }}></path>{` `}</svg></a>{`আমাদের অন্যান্য মেজবানগণ`}</h3>
      <AuthorList authors={authors.filter(author => author.id !== 'sh4hids')} mdxType="AuthorList" />{' '}
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      